import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { UserFollowersCountFragment } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import localStyles from './UserFollowersCount.module.css';

interface Props {
  /**
   * The user to display the messages count for.
   */
  user: UserFollowersCountFragment;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Set a custom element for message count component.
   */
  countElement?: React.ElementType;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Class name(s) to apply to the counts span element.
   */
  countClassName?: string;
  /**
   * Class name(s) to apply to the span element.
   */
  textClassName?: string;
}

/**
 * Display a user's followers count.
 *
 * @constructor
 *
 * @author Neha Anandpara
 */
const UserFollowersCount: React.FC<React.PropsWithChildren<Props>> = ({
  user,
  as: Component = 'small',
  countElement: FollowersCountComponent = 'span',
  className,
  countClassName,
  textClassName
}) => {
  const cx = useClassNameMapper(localStyles);
  const { FormattedMessage, loading: textLoading } = useTranslation(
    EndUserComponent.USER_FOLLOWERS_COUNT
  );

  if (textLoading) {
    return null;
  }

  const { followersCount } = user;

  return !textLoading && followersCount ? (
    <Component className={cx(className, 'lia-followers-count')}>
      <FormattedMessage
        id="title"
        values={{
          count: followersCount,
          span: function renderChunks(chunks): React.ReactNode {
            return <span className={cx(textClassName)}>&nbsp;{chunks}</span>;
          },
          followersCountRender: function renderCount(): React.ReactNode {
            return (
              <FollowersCountComponent className={cx(countClassName)}>
                {followersCount}
              </FollowersCountComponent>
            );
          }
        }}
      />
    </Component>
  ) : null;
};

export default UserFollowersCount;
