import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { FormattedDate } from 'react-intl';
import type { UserRegistrationDataFragment } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';

interface Props {
  /**
   * The user to display the join date for.
   */
  user: UserRegistrationDataFragment;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Prepend the text "Joined" to the date.
   */
  textKey: 'noPrefix' | 'withPrefix';
}

/**
 * Displays the user's community registration date.
 *
 * @author Neha Anandpara
 */
const UserRegistrationDate: React.FC<React.PropsWithChildren<Props>> = ({
  user,
  as: Component = 'span',
  className,
  textKey = 'noPrefix'
}: Props) => {
  const cx = useClassNameMapper();
  const { formatMessageAsNode, loading: textLoading } = useTranslation(
    EndUserComponent.USER_REGISTRATION_DATE
  );

  if (textLoading) {
    return null;
  }

  return user?.registrationData?.registrationTime ? (
    <Component className={cx(className)} data-testid="UserRegistrationDate">
      {formatMessageAsNode(textKey, {
        date: (
          <FormattedDate
            value={user.registrationData.registrationTime}
            year="numeric"
            month="long"
            day="2-digit"
          />
        )
      })}
    </Component>
  ) : null;
};

export default UserRegistrationDate;
