import type { UserBiographyFragment } from '@aurora/shared-generated/types/graphql-types';
import type { ClampLinesType } from '@aurora/shared-types/community/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';

interface Props {
  /**
   * The user to display the biography for.
   */
  user: UserBiographyFragment;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * The number of lines to clamp.
   */
  clampLines?: ClampLinesType;
}

/**
 * Display the biography provided by the user.
 *
 * @constructor
 *
 * @author Martin Sandoval
 */
const UserBiography: React.FC<React.PropsWithChildren<Props>> = ({
  user,
  as: Component = 'span',
  className,
  clampLines = 1
}) => {
  const cx = useClassNameMapper();
  const { biography = '' } = user;

  return biography?.trim().length > 0 ? (
    <Component className={cx({ [`lia-g-clamp lia-g-clamp-${clampLines}`]: clampLines }, className)}>
      {user.biography}
    </Component>
  ) : null;
};

export default UserBiography;
